<template>
  <section class="hero is-fullheight">
    <div class="hero-head">
      <div class="container">
        <slot name="hero-head"></slot>
      </div>
    </div>
    <div class="hero-body">
      <div class="container is-max-desktop">
        <slot name="hero-body"></slot>
      </div>
    </div>
    <div class="hero-foot">
      <div class="container">
        <slot name="hero-foot"></slot>
      </div>
    </div>
  </section>
</template>
