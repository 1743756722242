<script setup>
const props = defineProps([
  "title",
  "description",
  "tech",
  "link"
]);

/** Accepts a string that represents a URL and removes the schema **/
const removeHttp = (urlStr) => {
  const fmtUrl = urlStr.replace(/^https?:\/\//, "");
  if (fmtUrl.endsWith("/")) return fmtUrl.replace("\/", "");
  return fmtUrl;
};
</script>

<template>
  <div>
    <div class="case-content content">
      <p>
        {{ description }}
      </p>

      <h3 class="subtitle is-3">{{ $t(tech.title) }}</h3>
      <ul>
        <li v-for="(tech, index) in tech.technologies" :key="index">
          {{ tech }}
        </li>
      </ul>

      <div class="case-link is-flex is-align-items-center py-4">
        <a target="_blank" :href="link.url" :title="$t(link.title)">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="icon is-small" aria-hidden>
            <path
              d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z" />
          </svg>
          {{ removeHttp(link.url) }}</a>
      </div>
    </div>
  </div>
</template>
