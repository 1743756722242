<script setup>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";

gsap.registerPlugin(ScrollTrigger);
const timeline = gsap.timeline();

const onEnter = (el) => {
  timeline.from(el, {
    width: "0%",
    scrollTrigger: {
      trigger: el.parentElement,
      start: "-200px center",
      end: "bottom center",
      scrub: 2,
    },
  });
};
</script>

<template>
  <Transition appear @enter="onEnter">
    <span class="hl" aria-hidden="true" />
  </Transition>
</template>
