<script setup>
import { useI18n } from "vue-i18n";
import { vOnClickOutside } from '@vueuse/components'

const { locale, availableLocales } = useI18n({ useScope: 'global' })

function toggleDropDown(event) {
    event.target.closest(".dropdown").classList.toggle("is-active");
}
function closeDropdown(event) {
    const el = document.querySelector(".dropdown.is-active")
    if (el) {
        el.classList.toggle("is-active");
    }

}
function toggleLocale(event, l) {
    locale.value = l;
    closeDropdown()
}
</script>

<template>
    <div id="language-selector" class="navbar-item dropdown is-hidden-mobile is-hidden-tablet-only">
        <div id="locale" class="dropdown-trigger locale-changer">
            <button class="button" @click.prevent="toggleDropDown">
                <span class="icon">
                    <img :src="`/lang/${locale.toLowerCase()}.svg`" :alt="locale">
                </span>
            </button>
        </div>
        <div class="dropdown-menu" id="dropdown-menu3" role="menu" v-on-click-outside="closeDropdown">
            <div class="dropdown-content">
                <a v-for="l in availableLocales" class="dropdown-item is-vcentered" :href="`#${l}`"
                    @click.prevent="(e) => toggleLocale(e, l)">
                    <span class="icon-text">
                        <img class="icon" :src="`/lang/${l.toLowerCase()}.svg`" :alt="l" />
                        <span>{{ l }}</span>
                    </span>
                </a>
            </div>
        </div>
    </div>
</template>