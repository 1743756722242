<script setup>
import Lang from "./Lang.vue";
import { inject } from "vue";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const items = inject("navbar.items", []);

gsap.registerPlugin(ScrollTrigger);

const timeline = gsap.timeline();

const onAfterEnter = (el) => {
  const triggerEl = el.dataset.trigger;
  if (triggerEl === "olmo" || triggerEl === "presentes" || triggerEl === "") return;

  timeline.to(el, {
    scrollTrigger: {
      trigger: `#${el.dataset.trigger}`,
      start: "top center",
      end: "bottom center",
      scrub: true,
      onToggle: (self) => {
        self.isActive
          ? el.classList.add("active")
          : el.classList.remove("active");
      },
    },
  });
};
</script>

<template>
  <TransitionGroup
    appear
    @after-enter="onAfterEnter"
    tag="ul"
    class="is-flex-desktop is-align-items-center"
  >
    <li
      v-for="(item, index) in [...items]"
      :key="index"
      :data-trigger="item.href.slice(1)"
    >
      <router-link :to="item.href" :title="$t(item.text)" class="navbar-item">
        {{ $t(item.text) }}
      </router-link>
    </li>
  </TransitionGroup>
    <lang />

</template>
