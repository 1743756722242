<script setup>
import Hero from "../../layouts/Hero.vue";

const cards = [
  {
    title: "highlights.cards.1.title",
    content: "highlights.cards.1.content",
  },
  {
    title: "highlights.cards.2.title",
    content: "highlights.cards.2.content",
  },
  {
    title: "highlights.cards.3.title",
    content: "highlights.cards.3.content",
  },
  {
    title: "highlights.cards.4.title",
    content: "highlights.cards.4.content",
  },
  {
    title: "highlights.cards.5.title",
    content: "highlights.cards.5.content",
  },
  {
    title: "highlights.cards.6.title",
    content: "highlights.cards.6.content",
  },
];
</script>

<template>
  <Hero id="highlights" class="highlights">
    <template #hero-body>
      <h3 class="title">{{ $t("highlights.title") }}</h3>
      <div class="columns is-multiline">
        <div class="column is-half-tablet" v-for="card in cards">
          <div class="box">
            <p>
              <strong>
                {{ $t(card.title) }}
              </strong>
              {{ $t(card.content) }}
            </p>
          </div>
        </div>
      </div>
    </template>
  </Hero>
</template>
