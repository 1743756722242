<script setup>
import Hero from "../../layouts/Hero.vue";
import Case from './Case.vue';
import data from "../../store/projects.json";
import UnderlineTitle from "../../components/UnderlineTitle.vue";

const projects = data.projects.filter((x) => x["front-page"]);
</script>

<template>
  <Hero class="case-desc">
    <template #hero-body>
      <div class="content">
        <div class="title-container">
          <h2 class="title is-1 mb-6">
            {{ $t(data.sectionTitle) }}
          </h2>
          <underline-title />
        </div>
        <div class="is-multiline is-paddingless">
          <p>{{ $t("cases.sectionContent.firstCol") }}</p>
          <div class="logos">
            <img
              v-for="(project, index) in projects"
              :src="project.logo"
              :alt="$t(project.title)"
              :key="index"
            />
          </div>
        </div>
      </div>
    </template>
  </Hero>

  <template v-for="project in projects">
    <Case :project="project"/>
  </template>
</template>
