<script setup>
import PageFooter from "../layouts/PageFooter.vue";

const socialNetworks = [
  {
    name: "LinkedIn",
    logo: "/logos/linkedin.png",
    url: "https://www.linkedin.com/company/bigpear/",
    urlTitle: "footer.socialNetworks[0].urlTitle",
  },
  {
    name: "Wagtail",
    logo: "/logos/wagtail-logo-new.svg",
    url: "https://madewithwagtail.org/developers/bigpear/",
    urlTitle: "footer.socialNetworks[1].urlTitle",
  },
  {
    name: "YouTube",
    logo: "/logos/youtube.png",
    url: "https://www.youtube.com/%40bigpear9667",
    urlTitle: "footer.socialNetworks[2].urlTitle",
  },
];
</script>

<template>
  <page-footer>
    <template #footer-content>{{ $t("footer.content") }}</template>
    <template #social-networks>
      <ul class="networks">
        <li
          v-for="({ name, logo, url, urlTitle }, index) in socialNetworks"
          :key="index"
        >
          <a :href="url" :title="$t(urlTitle)" target="_blank">
            <!-- TODO: Create component to apply fill on hover -->
            <img :src="logo" aria-hidden="true" />
            {{ name }}
          </a>
        </li>
      </ul>
    </template>
  </page-footer>
</template>
