<script setup>
import { ref, provide } from "vue";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";

import AniBp from "../../components/IntroAnimations/AniBp.vue";
import Contact from "../../components/Contact.vue";
import WhatAreWe from "./Main.vue";
import AboutUs from "./AboutUs.vue";
import Cases from "./Cases.vue";


const tl = gsap.timeline({
  autoRemoveChildren: true,
  defaults: { opacity: 0 },
  onComplete: function () {
    this.kill();
    sessionStorage.setItem("aniBP", null);
    ScrollTrigger.refresh();
  },
});

const items = ref([
  {
    text: "navBigPear.about",
    href: "#about-us",
  },
  {
    text: "navBigPear.cases",
    href: "#cases",
  },
  {
    text: "navBigPear.contact",
    href: "#contact",
  },
  { text: "nav.olmo", href: "/olmo" },
]);

provide("navbar.items", items);
</script>

<template>
  <div class="bigpear-landing">
    <ani-bp :timeline="tl" />
    <what-are-we :timeline="tl" />
    <about-us />
    <div id="cases">
      <cases />
    </div>
    <contact />
  </div>
</template>

<style lang="scss">
.bigpear-landing .go-to-bigpear {
  display: none;
}
</style>
