<script setup>
import { ref, provide } from "vue";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
import Contact from "../../components/Contact.vue";

import Splash from "./Main.vue";
import What from "./What.vue";
import Who from "./Who.vue";


const tl = gsap.timeline({
  autoRemoveChildren: true,
  defaults: { autoAlpha: 0 },
  onComplete: function () {
    this.kill();
    sessionStorage.setItem("aniOlmo", null);
    ScrollTrigger.refresh();
  },
});

const items = ref([
  {
    text: "navPresentes.whatIs",
    href: "#what-is-presentes",
  },
  {
    text: "navPresentes.whoWeAre",
    href: "#who-we-are",
  },
  {
    text: "navPresentes.whatWeDo",
    href: "#what-we-do",
  },
  {
    text: "navPresentes.contact",
    href: "#contact",
  },
  {
    text: "nav.bigpear",
    href: "/",
  },
  {
    text: "nav.olmo",
    href: "/olmo",
  },
]);

provide("navbar.items", items);
</script>

<template>
  <div class="presentes-landing">
    <splash />
    <who />
    <what />
    <contact />
  </div>
</template>

<style lang="scss">
.presentes-landing .go-to-presentes {
  display: none;
}
</style>
