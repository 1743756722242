<script setup>
import { Timeline } from "gsap/gsap-core";

import Hero from "../../layouts/Hero.vue";
import NavbarMenu from "../../components/Header/NavBarMenu.vue";
import Pear from "../../components/Pear.vue";
import Logo from "./Logo.vue";
const tl = defineProps({ timeline: Timeline });

const displayAni = JSON.parse(sessionStorage.getItem("aniBP"));

const onBeforeEnterTitle = (el) => {
  tl.timeline.from(el.children[0], {
    scaleX: 0,
    transformOrigin: "left",
    duration: 0.45,
    onComplete: () => document.body.classList.remove("scroll-disabled"),
  });
};

const onBeforeEnterList = (el) => tl.timeline.from(el, { y: -25 });
</script>

<template>
  <Hero class="what-are-we is-fullheight-with-navbar" id="what-are-we">
    <template #hero-head>
      <navbar-menu>
        <template #brand>
            <Logo />
        </template>
      </navbar-menu>
    </template>
    <template #hero-body>
      <div class="columns is-multiline is-centered">
        <div class="column is-three-quarters-desktop">
          <div class="content is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
            <Transition :appear="displayAni" @before-enter="onBeforeEnterTitle">
              <h1 class="title">
                {{ $t("whatAreWe.title") }}
                <span class="hl"></span>
              </h1>
            </Transition>
            <TransitionGroup tag="ul" class="list ml-0" :appear="displayAni" @before-enter="onBeforeEnterList">
              <li v-for="(el, index) in [1, 2, 3, 4]" :key="index" :data-index="index">
                {{ $t(`whatAreWe.copy.${el}`) }}
              </li>
            </TransitionGroup>
          </div>
        </div>
        <pear />
      </div>
    </template>
    <template #hero-foot>
      <section class="is-flex is-justify-content-center made-with-wagtail pb-6">
        <img src="/logos/wagtail-logo-new.svg" aria-hidden="true" />
        <a href="https://madewithwagtail.org/developers/bigpear/" target="_blank" :title="$t('whatAreWe.wagtail')">
          {{ $t("whatAreWe.wagtail") }}
        </a>
      </section>
    </template>
  </Hero>
</template>
