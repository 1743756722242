<template>
    <nav class="navbar" role="navigation" aria-label="main navigation">
        <div class="container">
            <div class="navbar-brand">
                <slot name="navbar-brand"></slot>
                <slot name="navbar-mobile"></slot>
            </div>
            <div id="navbarApp" class="navbar-menu">
                <div class="navbar-end">
                    <slot name="navbar-end"></slot>
                </div>
            </div>
        </div>
    </nav>
</template>
