<script setup>
import PageFooter from "./components/PageFooter.vue";
import ModalVideo from "./components/ModalVideo.vue";

sessionStorage.setItem("aniBP", "true");
sessionStorage.setItem("aniOlmo", "true");
</script>

<template>
  <router-view v-slot="{ Component }">
    <transition name="page-opacity" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>
  <page-footer />
  <modal-video></modal-video>
</template>

<style lang="scss" scoped>
.page-opacity-enter-active,
.page-opacity-leave-active {
  transition: 600ms ease all;
}

.page-opacity-enter-from,
.page-opacity-leave-to {
  opacity: 0;
}
</style>
