<script setup>
import { Timeline } from "gsap/gsap-core";

const tl = defineProps({ timeline: Timeline });

const displayAni = JSON.parse(sessionStorage.getItem("aniBP"));

// Add class to disable scroll on animation
if (displayAni) document.body.classList.add("scroll-disabled");

const onBeforeEnterWrapper = (el) => {
  tl.timeline
    .from(el, {
      display: "flex",
      opacity: 1,
    })
    .to(el, { opacity: 0, duration: 0.5 }, "<");
};

const onBeforeEnterPear = (el) => {
  if (el.dataset.index == "0") {
    tl.timeline.from(el, { opacity: 0, duration: 1 });
  } else {
    tl.timeline.from(
      el,
      {
        rotation: 35,
        opacity: 1,
        transformOrigin: "bottom",
        ease: "bounce.out",
        duration: 1,
      },
      "-=0.5",
    );
  }
};

const beforeLogoDefaults = {
  duration: 0.25,
  scale: 0.8,
  transformOrigin: "center",
};

const onBeforeEnterLogo = (el) => {
  const dataIndex = el.dataset.index;
  if (dataIndex != 3) {
    tl.timeline.from(el, {
      ...beforeLogoDefaults,
    });
  } else {
    tl.timeline.from(el, {
      ...beforeLogoDefaults,
      delay: 0.4,
    });
  }
};
</script>

<template>
  <Transition :appear="displayAni" @before-enter="onBeforeEnterWrapper">
    <div class="wrapper">
      <svg
        class="is-hidden-touch"
        width="147"
        height="474"
        viewBox="0 0 147 474"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
      >
        <TransitionGroup :appear="displayAni" @before-enter="onBeforeEnterPear">
          <path
            key="pear"
            data-index="0"
            d="M107.809 237.851C89.578 219.044 81.848 193.105 84.061 167.005C84.5365 161.345 84.3967 155.65 83.644 150.02C79.099 115.728 50.978 99.7205 16.595 96.5475C11.0794 95.9814 5.52205 95.9479 0 96.4475V473.559C27.9544 475.143 55.7362 468.255 79.7134 453.796C103.691 439.338 122.748 417.982 134.394 392.519C146.04 367.057 149.733 338.674 144.99 311.079C140.247 283.484 127.288 257.963 107.809 237.851Z"
            fill="#C3FA78"
          />
          <path
            key="stem"
            data-index="1"
            d="M47.7913 15.1795C47.8085 12.7248 47.2258 10.303 46.0941 8.12469C44.9625 5.94634 43.3158 4.07728 41.2975 2.68005C39.2792 1.28281 36.9501 0.399652 34.5128 0.107293C32.0755 -0.185067 29.6038 0.122239 27.3123 1.0025L27.3252 0.930474C27.1532 1.00247 26.9483 1.09348 26.7493 1.17748L26.6552 1.21648C21.6552 3.37948 9.90423 9.38548 3.77523 22.3105C-4.05877 38.8335 3.28724 56.7005 8.56124 66.1445C8.75632 66.4898 9.06242 66.7589 9.42977 66.9083C9.79713 67.0576 10.2042 67.0784 10.5848 66.9672C10.9654 66.8559 11.2973 66.6193 11.5265 66.2957C11.7557 65.9721 11.8687 65.5805 11.8473 65.1845C11.3823 57.5655 12.1303 45.9355 19.4473 38.0365C24.2883 32.8085 30.7582 30.9365 35.1272 30.1535L35.0883 30.1115C38.6317 29.5316 41.854 27.712 44.1806 24.9772C46.5071 22.2423 47.7868 18.7701 47.7913 15.1795Z"
            fill="#E6555A"
          />
        </TransitionGroup>
      </svg>
      <svg
        class="logoBP"
        width="719"
        height="83"
        viewBox="0 0 719 83"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
      >
        <g clip-path="url(#clip0_37_51)">
          <TransitionGroup
            :appear="displayAni"
            @before-enter="onBeforeEnterLogo"
          >
            <path
              key="b"
              data-index="0"
              d="M49.9 38.25C49 38.05 48.9 36.85 49.7 36.45C54.7 33.55 57.7 27.95 57.7 20.75C57.7 8.74997 48.9 1.34998 34.7 1.34998H0V81.35H38.2C54.6 81.35 64.5 72.45 64.5 59.05C64.5 48.35 58.8 40.85 49.9 38.25ZM36.7 73.65H8.5V8.94997H33.3C43.5 8.94997 49.3 13.65 49.3 22.25C49.3 27.95 46.5 31.95 41.5 33.85C39.6 34.55 38.2 36.35 38.2 38.35V38.45C38.2 40.75 39.9 42.75 42.2 43.15C50.7 44.65 55.5 49.95 55.5 58.05C55.4 67.95 48.6 73.65 36.7 73.65Z"
              fill="#FFFFFF"
            />
            <path
              key="i"
              data-index="1"
              d="M113.5 81.35V1.34998H122V81.35H113.5Z"
              fill="#FFFFFF"
            />
            <path
              key="g"
              data-index="2"
              d="M182.7 71.1C174.9 63.3 171 53.4 171 41.4C171 29.4 174.9 19.6 182.7 11.8C190.6 3.9 200.3 0 212.1 0C222.2 0 230.6 2.5 237.5 7.7V16.3C230.1 10.7 221.8 7.9 212.6 7.9C203.4 7.9 195.3 11.1 189.1 17.4C182.8 23.7 179.7 31.8 179.7 41.5C179.7 51.2 182.8 59.2 189 65.5C195.3 71.8 203 74.9 212 74.9C221.6 74.9 229.1 72.2 234.8 66.5V35.4H243.2V69.8C236.3 77.5 225 82.7 212.1 82.7C200.4 82.7 190.7 78.9 182.7 71.1Z"
              fill="#FFFFFF"
            />
            <path
              key="p"
              data-index="3"
              d="M292.2 81.25V1.25H327.2C335.8 1.25 342.6 3.55 347.5 8.05C352.5 12.55 355 18.25 355 25.45C355 32.65 352.5 38.45 347.5 42.95C342.6 47.45 335.7 49.75 327.2 49.75H308.7V81.45L292.2 81.25ZM308.7 35.55H326.1C334 35.55 338.7 32.05 338.7 25.55C338.7 19.05 334 15.55 326.1 15.55H308.7V35.55Z"
              fill="#FFFFFF"
            />
            <path
              key="e"
              data-index="4"
              d="M404 81.3V1.29999H462.7V16.1H420.4V31.5H454.5V46H420.4V66.6H464V81.4L404 81.3Z"
              fill="#FFFFFF"
            />
            <path
              key="a"
              data-index="5"
              d="M563 1.09998H547.3L513 81.6H530.2L555 21.7L579.8 81.6H597.3L563 1.09998Z"
              fill="#FFFFFF"
            />
            <path
              key="r"
              data-index="6"
              d="M646.3 81.35V1.34998H683.3C691.8 1.34998 698.5 3.54997 703.4 7.94997C708.3 12.25 710.8 18.05 710.8 24.95C710.8 35.55 704.6 43.95 694.2 47.15L718.9 81.35H699.8L676.5 48.95H662.7V81.35H646.3ZM662.7 35.15H682.4C689.8 35.15 694.3 31.75 694.3 25.45C694.3 19.15 689.8 15.75 682.4 15.75H662.7V35.15Z"
              fill="#FFFFFF"
            />
          </TransitionGroup>
        </g>
      </svg>
    </div>
  </Transition>
</template>
<style lang="scss" scoped>
.wrapper {
  background-color: #527f2e;
  position: relative;
  display: none;
  opacity: 0;
  z-index: 99;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  height: 100dvh;
  width: 100dvw;
  padding: 1rem;
}

.logoBP {
  margin-top: 6rem;
}
</style>
