<script setup>
import { Timeline } from "gsap/gsap-core";

const tl = defineProps({ timeline: Timeline });

const displayAni = JSON.parse(sessionStorage.getItem("aniOlmo"));

const onBeforeEnter = (el) => {
  const leaf = Number(el.dataset.leaf);
  if (leaf === typeof NaN) return;
  if (leaf === 0)
    tl.timeline.from(el, {
      rotation: -10,
      transformOrigin: "bottom",
      duration: 1,
    });
  if (leaf === 1)
    tl.timeline.from(el, {
      rotation: 4,
      transformOrigin: "left",
      duration: 1,
    });
  if (leaf === 2)
    tl.timeline.from(
      el,
      {
        rotation: 8,
        transformOrigin: "left",
        duration: 1.3,
      },
      "<0.5"
    );
};
</script>
<template>
  <svg
    width="1414"
    height="1483"
    viewBox="0 0 1414 1483"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <TransitionGroup :appear="displayAni" @before-enter="onBeforeEnter">
      <path
        key="0"
        data-leaf="0"
        d="M310.97 -32.4884C112.744 298.614 16.9298 703.13 63.9944 1126.93C764.672 1049.11 1312.69 519.457 1484.61 -162.827L310.97 -32.4884Z"
        fill="#C5F400"
      />
      <path
        key="1"
        data-leaf="1"
        d="M1246.69 506.188C1312.87 526.416 1351.46 533.355 1419.88 546.916L1466.64 187.949C1338.27 305.163 1307.53 377.739 1246.69 506.188Z"
        fill="#C5F400"
      />
      <path
        key="2"
        data-leaf="2"
        d="M959.006 770.612C1092.07 899.475 1261.16 969.627 1429.96 980.159L1416.29 586.63C1247.94 587.935 1083.35 648.969 959.006 770.612Z"
        fill="#C5F400"
      />
      <g key="3" opacity="0.28">
        <mask
          id="mask0_686_1019"
          style="mask-type: luminance"
          maskUnits="userSpaceOnUse"
          x="845"
          y="800"
          width="373"
          height="550"
        >
          <path
            d="M1217.95 800.81H845.94V1349.67H1217.95V800.81Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_686_1019)">
          <mask
            id="mask1_686_1019"
            style="mask-type: luminance"
            maskUnits="userSpaceOnUse"
            x="845"
            y="800"
            width="373"
            height="550"
          >
            <path
              d="M845.94 1217.82C947.49 1300.26 1076.95 1349.67 1217.95 1349.67C1217.95 1100.7 1063.91 887.71 845.94 800.81V1217.82Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask1_686_1019)">
            <path
              d="M1162.18 667.997L649.997 883.087L901.708 1482.47L1413.89 1267.38L1162.18 667.997Z"
              fill="url(#paint0_linear_686_1019)"
            />
          </g>
        </g>
      </g>
      <defs key="4">
        <linearGradient
          id="paint0_linear_686_1019"
          x1="928.806"
          y1="1122.9"
          x2="1534.77"
          y2="868.423"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="0.13" stop-color="#FDFDFD" />
          <stop offset="0.18" stop-color="#F6F6F6" />
          <stop offset="0.21" stop-color="#EAEAEA" />
          <stop offset="0.24" stop-color="#D9D9D9" />
          <stop offset="0.26" stop-color="#C3C3C3" />
          <stop offset="0.29" stop-color="#A8A8A8" />
          <stop offset="0.3" stop-color="#878787" />
          <stop offset="0.32" stop-color="#626262" />
          <stop offset="0.34" stop-color="#373737" />
          <stop offset="0.35" />
          <stop offset="1" />
        </linearGradient>
      </defs>
    </TransitionGroup>
  </svg>
</template>
<style lang="scss" scoped>
svg {
  position: absolute;
  height: fit-content;
  width: 100vw;
  top: 0;
}
</style>
