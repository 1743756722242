<script setup>
import { useParallax } from '@vueuse/core'
import Hero from "../../layouts/Hero.vue";
import CaseImage from "../../components/SuccessCase/CaseImage.vue";
import Description from "../../components/SuccessCase/Description.vue";
import { computed, reactive, ref } from 'vue'

const target = ref(null)
const parallax = reactive(useParallax(target))
const props = defineProps(["project"]);

const containerStyle = {
  perspective: '300px',
}
const layerBase = {
  transition: '.3s ease-out all',
}

const layer0 = computed(() => ({
  ...layerBase,
  transform: `translateX(${parallax.tilt * 10}px) translateY(${parallax.roll * 10
    }px) scale(1.33)`,
}))

const layer1 = computed(() => ({
  ...layerBase,
  transform: `translateX(${parallax.tilt * 20}px) translateY(${parallax.roll * 20
    }px) scale(1)`,
}))

const layer2 = computed(() => ({
  ...layerBase,
  transform: `translateX(${parallax.tilt * 30}px) translateY(${parallax.roll * 30
    }px) scale(1.01)`,
}))

const layer3 = computed(() => ({
  ...layerBase,
  transform: `translateX(${parallax.tilt * 40}px) translateY(${parallax.roll * 40
    }px) scale(1.33)`,
}))

</script>
<template>
  <Hero :class="`is-${project.slug} case`" ref="target" :style="containerStyle">
    <template #hero-body>
      <img class="case-el" :src="project.caseEl" aria-hidden="true" :style="layer3" />
      <img class="case-logo pt-6 ml-4" :src="project.logo" :alt="$t(project.title)" :style="layer0" />
      <div class="is-multiline is-centered">
        <case-image :images="project.preview" :alt="$t(project.title)" :style="layer1"/>
        <description :title="$t(project.title)" :description="$t(project.description)" :tech="project.tech"
          :link="project.link" :style="layer2" />
      </div>
    </template>
  </Hero>
</template>
